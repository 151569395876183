.presentation_concrete_pool_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1080rem;
  max-height: 1080px;
  background-color: #0d2931;
}

.presentation_concrete_pool {
  position: relative;
  max-width: 1920rem;
  height: 1080rem;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%),
    url('./img/82e84e0bc4c92e190a61fa1bb010d52a.png');
  background-color: #f5f6f8;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.bread_crumbs {
  position: absolute;
  width: 322rem;
  height: 11rem;
  top: 124rem;
  left: 21.82%;
  right: 76.93%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bread_crumbs > p {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.presentation_concrete_pool > h1 {
  position: absolute;
  width: 1088rem;
  height: 125rem;
  top: 487rem;
  left: 18.38%;
  right: 25%;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

.presentation_concrete_pool_text {
  position: absolute;
  width: 591rem;
  height: 228rem;
  top: 670rem;
  left: 50.9%;
  right: 18.33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.presentation_concrete_pool_text > p {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.presentation_concrete_pool_text :first-child {
  height: 126rem;
}

.presentation_concrete_pool_text :last-child {
  height: 70rem;
}

.more_details_button {
  position: absolute;
  width: 186rem;
  height: 52rem;
  top: 947rem;
  left: 50.9%;
  right: 39.42%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.more_details_button > button {
  width: 52rem;
  height: 52rem;
  padding: 5rem 16rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 48rem;
  border: 1rem solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
}

.more_details_button > p {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 18rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}

.more_details_button > button > svg {
  width: 20rem;
  height: 20rem;
}

@media (max-width: 760px) {
  .presentation_concrete_pool_conteiner {
    position: relative;
    max-width: 760px;
    height: 800rem;
    max-height: 800rem;
    background-color: #0d2931;
  }

  .presentation_concrete_pool {
    position: relative;
    max-width: 760rem;
    height: 800rem;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%),
      url('./img/82e84e0bc4c92e190a61fa1bb010d52a.png');
    background-color: #f5f6f8;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .bread_crumbs {
    display: none;
  }

  .bread_crumbs > p {
    display: none;
  }

  .presentation_concrete_pool > h1 {
    position: absolute;
    width: 335rem;
    height: 51rem;
    top: 364rem;
    left: 0;
    right: 0;
    margin: auto;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 SemiBold';
    font-size: 26rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
  }

  .presentation_concrete_pool_text {
    position: absolute;
    width: 335rem;
    height: 221rem;
    top: 443rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .presentation_concrete_pool_text > p {
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
  }

  .presentation_concrete_pool_text :first-child {
    height: 125rem;
  }

  .presentation_concrete_pool_text :last-child {
    height: 68rem;
  }

  .more_details_button {
    position: absolute;
    width: 186rem;
    height: 52rem;
    top: 692rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .more_details_button > button {
    width: 52rem;
    height: 52rem;
    padding: 5rem 16rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 48rem;
    border: 1rem solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
  }

  .more_details_button > p {
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 18rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-transform: uppercase;
  }

  .more_details_button > button > svg {
    width: 20rem;
    height: 20rem;
  }
}
