.landscape_design_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1290rem;
  max-height: 1290px;
  background: linear-gradient(
      180deg,
      rgba(13, 41, 49, 0.5) 15.37%,
      #0d2931 100%
    ),
    url('./img/09519813b0e6ba30555c8046dbcdf39f.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #f5f6f8;
}

.landscape_design {
  position: relative;
  width: 1214rem;
  height: 962rem;
  top: 168rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.landscape_design_paragraph_text {
  width: 1089rem;
  height: 120rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.landscape_design_paragraph_text > h2 {
  height: 36rem;
  color: var(--PureWhite, #fff);
  text-align: center;
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.landscape_design_paragraph_text > h3 {
  height: 52rem;
  color: var(--PureWhite, #fff);
  text-align: center;
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.landscape_design_round_images {
  width: 1206rem;
  height: 470rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.round_img {
  position: absolute;
  width: 460rem;
  height: 470rem;
  border-radius: 470rem;
  border: 10rem solid #0d2931;
}

.round_images_1 {
  z-index: 1;
  background: url('./img/a9df6c3700642f5a9e0b67d5575eaab4.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #f5f6f8;
}

.round_images_2 {
  margin-left: 373rem;
  z-index: 2;
  background: url('./img/3db42f84c9110c247ce43ba73d950bff.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #f5f6f8;
}

.round_images_3 {
  margin-left: 746rem;
  z-index: 3;
  background: url('./img/98cb3455135b884463060039ad624d82.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #f5f6f8;
}

.landscape_design_about_text {
  width: 1214rem;
  height: 228rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.text_about_design {
  width: 590rem;
  height: 228rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text_about_design > p {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.text_about_design :first-child {
  height: 70rem;
}

.text_about_design :last-child {
  height: 126rem;
}

@media (max-width: 760px) {
  .landscape_design_conteiner {
    position: relative;
    max-width: 760px;
    height: 817rem;
    max-height: auto;
    padding: 72rem 20rem;
    background: linear-gradient(
        180deg,
        rgba(13, 41, 49, 0.5) 15.37%,
        #0d2931 100%
      ),
      url('./img/09519813b0e6ba30555c8046dbcdf39f.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: #f5f6f8;
  }

  .landscape_design {
    position: relative;
    width: 335rem;
    height: 672rem;
    top: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .landscape_design_paragraph_text {
    width: 335rem;
    height: 193rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .landscape_design_paragraph_text > h2 {
    height: 55rem;
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .landscape_design_paragraph_text > h3 {
    height: 114rem;
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Unbounded H3 Light';
    font-size: 18rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-transform: uppercase;
  }

  .landscape_design_round_images {
    width: 335rem;
    height: 127rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .round_img {
    position: absolute;
    width: 127rem;
    height: 127rem;
    border-radius: 127rem;
    border: 2.7rem solid #0d2931;
  }

  .round_images_1 {
    z-index: 1;
    background: url('./img/a9df6c3700642f5a9e0b67d5575eaab4.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: #f5f6f8;
  }

  .round_images_2 {
    margin-left: 105rem;
    z-index: 2;
    background: url('./img/3db42f84c9110c247ce43ba73d950bff.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: #f5f6f8;
  }

  .round_images_3 {
    margin-left: 210rem;
    z-index: 3;
    background: url('./img/98cb3455135b884463060039ad624d82.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: #f5f6f8;
  }

  .landscape_design_about_text {
    width: 335rem;
    height: 236rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .text_about_design {
    width: 335rem;
    height: 236rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text_about_design > p {
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    text-align: center;
  }

  .text_about_design :first-child {
    height: 87rem;
  }

  .text_about_design :last-child {
    height: 125rem;
  }
}
