.presentation_about_company_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1632rem;
  max-height: 1632px;
  background-color: #0d2931;
}

.bread_crumbs {
  position: absolute;
  width: 143rem;
  height: 11rem;
  top: 124rem;
  left: 21.82%;
  right: 76.93%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bread_crumbs > p {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.presentation_about_company {
  position: relative;
  max-width: 1920rem;
  height: 1372rem;
  top: 260rem;
}

.presentation_about_company > h1 {
  position: absolute;
  width: 1214rem;
  height: 202rem;
  left: 18.33%;
  right: 18.43%;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 Light';
  font-size: 64rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-transform: uppercase;
}

.presentation_about_company > h1 > span {
  color: var(--Gold-Light, #d09f76);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

.presentation_about_company > p {
  position: absolute;
  width: 384rem;
  height: 154rem;
  top: 308rem;
  left: 61.66%;
  right: 18.33%;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  z-index: 3;
}

.presentation_about_company_years_text {
  position: absolute;
  width: 904rem;
  height: 244rem;
  top: 874rem;
  left: 34.58%;
  right: 19%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
}

.presentation_about_company_years_text > h2 {
  height: 144rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H3 Extra Light';
  font-size: 192rem;
  font-style: normal;
  font-weight: 200;
  line-height: 75%;
  text-transform: uppercase;
}

.presentation_about_company_years_text > h3 {
  height: 52rem;
  color: var(--River, #83bdcd);
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-transform: uppercase;
}

.presentation_about_company_img {
  position: absolute;
  width: 1048rem;
  height: 896rem;
  top: 308rem;
  background: url('./img/0ca5e97c92f73586efe38c84dc3ad45b.png');
  background-color: #f5f6f8;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
}

.presentation_about_company_img_wave {
  position: absolute;
  top: 417rem;
  z-index: 1;
}

@media (max-width: 760px) {
  .presentation_about_company_conteiner {
    position: relative;
    max-width: 760px;
    height: 1024rem;
    max-height: 1024rem;
    top: 80rem;
    background-color: #0d2931;
  }

  .bread_crumbs {
    display: none;
  }

  .bread_crumbs > p {
    display: none;
  }

  .presentation_about_company {
    position: relative;
    max-width: 760px;
    height: 1024rem;
    top: 0;
  }

  .presentation_about_company > h1 {
    position: relative;
    width: 335rem;
    height: 144rem;
    left: 0;
    right: 0;
    top: 74rem;
    margin: 0 auto;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 Light';
    font-size: 26rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    text-transform: uppercase;
  }

  .presentation_about_company > h1 > span {
    color: var(--Gold-Light, #d09f76);
    font-family: 'Unbounded H1 Light';
    font-size: 26rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    text-transform: uppercase;
  }

  .presentation_about_company > p {
    position: absolute;
    width: 335rem;
    height: 99rem;
    top: 228rem;
    left: 0;
    right: 0;
    margin: 24rem auto 0 auto;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    z-index: 3;
  }

  .presentation_about_company_years_text {
    position: absolute;
    width: 335rem;
    height: 229rem;
    top: 723rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 3;
  }

  .presentation_about_company_years_text > h2 {
    height: 54rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H3 Extra Light';
    font-size: 72rem;
    font-style: normal;
    font-weight: 200;
    line-height: 75%;
    text-transform: uppercase;
  }

  .presentation_about_company_years_text > h3 {
    width: 300rem;
    height: 127rem;
    color: var(--River, #83bdcd);
    font-family: 'Unbounded H3 Light';
    font-size: 20rem;
    font-style: normal;
    font-weight: 300;
    line-height: 133%;
    text-transform: uppercase;
  }

  .presentation_about_company_img {
    position: absolute;
    width: 375rem;
    height: 320rem;
    top: 371rem;
    background: url('./img/0ca5e97c92f73586efe38c84dc3ad45b.png');
    background-color: #f5f6f8;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
  }

  .presentation_about_company_img_wave {
    display: none;
  }
}
