.advantage_concrete_pool_conteiner {
  position: relative;
  max-width: 1920px;
  height: 2302rem;
  max-height: 2302rem;
  padding: 168rem 10rem;
  background: var(--Light-Grey, #f5f6f8);
}

.advantage_concrete_pool {
  position: relative;
  width: 1216rem;
  height: 1966rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.advantage_concrete_pool > h1 {
  height: 94rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.advantage_concrete_pool > h1 > span {
  color: var(--Gold, #b88b66);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.advantage_concrete_pool_text_img {
  width: 1216rem;
  height: 1800rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.advantage_concrete_pool_card {
  width: 608rem;
  height: 300rem;
}

.text_acpc {
  padding: 0rem 72rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text_acpc > h3 {
  height: 20rem;
  margin-bottom: 28rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 28rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.text_acpc > p {
  height: 55rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.img_acpc {
  background-color: #f5f6f8;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img_acpc_1 {
  background-image: url(./img/30237646145d80ee74a4ffada2a7751a.png);
}
.img_acpc_2 {
  background-image: url(./img/1745f46bce86694d30a1d05b3860b23e.png);
}
.img_acpc_3 {
  background-image: url(./img/7f81b8017581e46c276c89484e0e3ec3.png);
}
.img_acpc_4 {
  background-image: url(./img/179af96e85b03ac9be31fcbcefeb898a.png);
}
.img_acpc_5 {
  background-image: url(./img/3ef477750fe367f09880815b84c9579d.png);
}
.img_acpc_6 {
  background-image: url(./img/d303c8734920d32207e694afc1bca6ed.png);
}

@media (max-width: 760px) {
  .advantage_concrete_pool_conteiner {
    position: relative;
    max-width: 760px;
    height: 2204rem;
    max-height: 2204rem;
    padding: 72rem 20rem;
    background: var(--Light-Grey, #f5f6f8);
  }

  .advantage_concrete_pool {
    position: relative;
    width: 335rem;
    height: 2060rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .advantage_concrete_pool > h1 {
    height: 123rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .advantage_concrete_pool > h1 > span {
    color: var(--Gold, #b88b66);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .advantage_concrete_pool_text_img {
    width: 335rem;
    height: 1865rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .advantage_concrete_pool_card {
    width: 335rem;
    height: 170rem;
  }

  .text_acpc {
    width: 335rem;
    height: auto;
    padding: 20rem 20rem 20rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .text_acpc > h3 {
    height: auto;
    margin-bottom: 16rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .text_acpc > p {
    height: auto;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro Light';
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .img_acpc {
    background-color: #f5f6f8;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .img_acpc_1 {
    background-image: url(./img/30237646145d80ee74a4ffada2a7751a.png);
    order: 1;
  }
  .tcp1 {
    order: 2;
  }
  .img_acpc_2 {
    background-image: url(./img/1745f46bce86694d30a1d05b3860b23e.png);
    order: 3;
  }
  .tcp2 {
    order: 4;
  }
  .img_acpc_3 {
    background-image: url(./img/7f81b8017581e46c276c89484e0e3ec3.png);
    order: 5;
  }
  .tcp3 {
    order: 6;
  }
  .img_acpc_4 {
    background-image: url(./img/179af96e85b03ac9be31fcbcefeb898a.png);
    order: 7;
  }
  .tcp4 {
    order: 8;
  }
  .img_acpc_5 {
    background-image: url(./img/3ef477750fe367f09880815b84c9579d.png);
    order: 9;
  }
  .tcp5 {
    order: 10;
  }
  .img_acpc_6 {
    background-image: url(./img/d303c8734920d32207e694afc1bca6ed.png);
    order: 11;
  }
  .tcp6 {
    order: 12;
  }
}
