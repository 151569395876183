.advantage_composite_pool_conteiner {
  position: relative;
  max-width: 1920px;
  height: 2302rem;
  max-height: 2302px;
  padding: 168rem 10rem;
  background: var(--Light-Grey, #f5f6f8);
}

.advantage_composite_pool {
  position: relative;
  width: 1216rem;
  height: 1966rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.advantage_composite_pool > h1 {
  height: 94rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.advantage_composite_pool > h1 > span {
  color: var(--Gold, #b88b66);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.advantage_composite_pool_text_img {
  width: 1216rem;
  height: 1800rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.advantage_composite_pool_card {
  width: 608rem;
  height: 300rem;
}

.text_acopc {
  padding: 0rem 72rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text_acopc > h3 {
  height: auto;
  margin-bottom: 28rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 28rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.text_acopc > p {
  height: 55rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.img_acopc {
  background-color: #f5f6f8;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.img_acopc_1 {
  background-image: url(./img/a6e478a7d938c4d53b189bded7eba1b0.png);
}
.img_acopc_2 {
  background-image: url(./img/1473d43a40a45f623b10a5c58ecaae76.png);
}
.img_acopc_3 {
  background-image: url(./img/c858427ef2b788e27bcbda167b279489.png);
}
.img_acopc_4 {
  background-image: url(./img/9f60150b3c928ab892e35d86b1ecfaac.png);
}
.img_acopc_5 {
  background-image: url(./img/d5458207bffd0eeb69cc46f4a2824dc3.png);
}
.img_acopc_6 {
  background-image: url(./img/f4541acac8c953a121a4a7fb6fe26248.png);
}

@media (max-width: 760px) {
  .advantage_composite_pool_conteiner {
    position: relative;
    max-width: 760px;
    height: 2214rem;
    max-height: 2214rem;
    padding: 72rem 20rem;
    background: var(--Light-Grey, #f5f6f8);
  }

  .advantage_composite_pool {
    position: relative;
    width: 335rem;
    height: 2070rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .advantage_composite_pool > h1 {
    height: 123rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .advantage_composite_pool > h1 > span {
    color: var(--Gold, #b88b66);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .advantage_composite_pool_text_img {
    width: 335rem;
    height: 1875rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .advantage_composite_pool_card {
    width: 335rem;
    height: 131rem;
  }

  .text_acopc {
    padding: 0rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .text_acopc > h3 {
    height: auto;
    margin-bottom: 16rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .text_acopc > p {
    height: auto;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro Light';
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .img_acopc {
    width: 335rem;
    height: 170rem;
    background-color: #f5f6f8;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .img_acopc_1 {
    background-image: url(./img/a6e478a7d938c4d53b189bded7eba1b0.png);
    order: 1;
  }
  .tc1 {
    order: 2;
  }
  .img_acopc_2 {
    background-image: url(./img/1473d43a40a45f623b10a5c58ecaae76.png);
    order: 3;
  }
  .tc2 {
    order: 4;
  }
  .img_acopc_3 {
    background-image: url(./img/c858427ef2b788e27bcbda167b279489.png);
    order: 5;
  }
  .tc3 {
    order: 6;
  }
  .img_acopc_4 {
    background-image: url(./img/9f60150b3c928ab892e35d86b1ecfaac.png);
    order: 7;
  }
  .tc4 {
    order: 8;
  }
  .img_acopc_5 {
    background-image: url(./img/d5458207bffd0eeb69cc46f4a2824dc3.png);
    order: 9;
  }
  .tc5 {
    order: 10;
  }
  .img_acopc_6 {
    background-image: url(./img/f4541acac8c953a121a4a7fb6fe26248.png);
    order: 11;
  }
  .tc6 {
    order: 12;
  }
}
