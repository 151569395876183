.our_finished_hammams_conteiner {
  position: relative;
  max-width: 1920px;
  height: 796rem;
  max-height: 796px;
  background-color: #0d2931;
}

.our_finished_hammams {
  position: relative;
  max-width: 1920px;
  height: 796rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.our_finished_hammams > h2 {
  color: var(--PureWhite, #fff);
  text-align: center;
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.our_finished_hammams_slider {
  width: 3088rem;
  height: 688rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 760px) {
  .our_finished_hammams_conteiner {
    position: relative;
    max-width: 760px;
    height: 330rem;
    max-height: 330rem;
    background-color: #0d2931;
    overflow: hidden;
  }

  .our_finished_hammams {
    position: relative;
    max-width: 760px;
    height: 330rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .our_finished_hammams > h2 {
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .our_finished_hammams_slider {
    width: 992rem;
    height: 276rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
