.reason_for_hammam_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1635rem;
  max-height: 1635px;
  padding-bottom: 264rem;
  background-color: rgba(13, 41, 49, 1);
}

.background_wave {
  position: absolute;
}

.reason_for_hammam {
  position: relative;
  width: 1216rem;
  height: 1371rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reason_for_hammam_text {
  width: 1212rem;
  height: 399rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reason_for_hammam_text > h1 {
  height: 144rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H3 Extra Light';
  font-size: 192rem;
  font-style: normal;
  font-weight: 200;
  line-height: 77%;
  text-transform: uppercase;
}

.reason_for_hammam_text > h2 {
  width: 682rem;
  height: 210rem;
  align-self: flex-end;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  text-transform: uppercase;
}

.reason_for_hammam_text > h2 > span {
  color: var(--Gold, #b88b66);
}

.reason_for_hammam_blocks {
  width: 1216rem;
  height: 900rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rfhbi {
  width: 608rem;
  height: 300rem;
  background-color: #f5f6f8;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.reason_for_hammam_block_img_1 {
  background-image: url('./img/873f4d0cee5652a76436600f602934fd.png');
}
.reason_for_hammam_block_img_2 {
  background-image: url('./img/cdc181c6db12f5f3c8bb994116e6d856.png');
}
.reason_for_hammam_block_img_3 {
  background-image: url('./img/2620adb196ee52a30beb2a893cde3931.png');
}

.rfhbt {
  width: 608rem;
  height: 300rem;
  padding: 0 72rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rfhbt > h3 {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 28rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.rfhbt > p {
  margin-top: 28rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@media (max-width: 760px) {
  .reason_for_hammam_conteiner {
    position: relative;
    max-width: 760px;
    height: 1532rem;
    max-height: 1532rem;
    padding: 72rem 20rem;
    background-color: rgba(13, 41, 49, 1);
  }

  .background_wave {
    display: none;
  }

  .reason_for_hammam {
    position: relative;
    width: 335rem;
    height: 1388rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .reason_for_hammam_text {
    position: relative;
    width: 335rem;
    height: 212rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .reason_for_hammam_text > h1 {
    height: 54rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H3 Extra Light';
    font-size: 72rem;
    font-style: normal;
    font-weight: 200;
    line-height: 77%;
    text-transform: uppercase;
    text-align: center;
  }

  .reason_for_hammam_text > h2 {
    width: 335rem;
    height: 123rem;
    align-self: center;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    text-transform: uppercase;
    text-align: center;
  }

  .reason_for_hammam_text > h2 > span {
    color: var(--Gold, #b88b66);
  }

  .reason_for_hammam_blocks {
    width: 335rem;
    height: 1104rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .rfhbi {
    width: 335rem;
    height: 170rem;
    background-color: #f5f6f8;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .reason_for_hammam_block_img_1 {
    order: 1;
    background-image: url('./img/873f4d0cee5652a76436600f602934fd.png');
  }

  .reason_for_hammam_block_text_1 {
    order: 2;
  }
  .reason_for_hammam_block_img_2 {
    order: 3;
    background-image: url('./img/cdc181c6db12f5f3c8bb994116e6d856.png');
  }

  .reason_for_hammam_block_text_2 {
    order: 4;
  }
  .reason_for_hammam_block_img_3 {
    order: 5;
    background-image: url('./img/2620adb196ee52a30beb2a893cde3931.png');
  }
  .reason_for_hammam_block_text_3 {
    order: 6;
  }

  .rfhbt {
    width: 335rem;
    height: auto;
    padding: 20rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rfhbt > h3 {
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .rfhbt > p {
    margin-top: 16rem;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Light';
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
