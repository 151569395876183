@font-face {
  font-family: 'Unbounded H1 Light';
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  src: local('Unbounded'), url('./font/Unbounded/static/Unbounded-Light.ttf');
}

@font-face {
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  src: local('Unbounded'), url('./font/Unbounded/static/Unbounded-SemiBold.ttf');
}

@font-face {
  font-family: 'Unbounded H2 Regular';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  src: local('Unbounded'), url('./font/Unbounded/static/Unbounded-Regular.ttf');
}

@font-face {
  font-family: 'Unbounded H3 Light';
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  src: local('Unbounded'), url('./font/Unbounded/static/Unbounded-Light.ttf');
}

@font-face {
  font-family: 'Unbounded H3 Extra Light';
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: 140%;
  src: local('Unbounded'),
    url('./font/Unbounded/static/Unbounded-ExtraLight.ttf');
}

@font-face {
  font-family: 'Gotham Pro'; 
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  src: local('Gotham Pro'), url('./font/Gotham-Pro/gothampro.ttf');
}

@font-face {
  font-family: 'Gotham Pro Light'; 
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  src: local('Gotham Pro'), url('./font/Gotham-Pro/gothampro_light.ttf');
}

@font-face {
  font-family: 'Gotham Pro Bold'; 
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  src: local('Gotham Pro'), url('./font/Gotham-Pro/gothampro_bold.ttf');
}

@font-face {
  font-family: 'Gotham Pro Medium'; 
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  src: local('Gotham Pro'), url('./font/Gotham-Pro/gothampro_medium.ttf');
}

html {
  font-size: calc(1vw / 19.2);
}

/*нужно подобрать правильное соотношение*/
@media (max-width: 760px) {
  html {
    font-size: calc(1vw / 3.75);
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  /* эксперементальное значение */
  scroll-behavior: smooth;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  overflow-x: hidden;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 1920px) {
  html {
    font-size: 1px;
  }
}
