.map_address_contacts_conteiner {
  position: relative;
  max-width: 1920px;
  height: auto;
  background-color: rgba(13, 41, 49, 1);
}

.map_address_contacts {
  position: relative;
  max-width: 1920px;
  width: 1920rem;
  height: 100vh;
  gap: 130rem;
  top: 92rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map_module_conteiner {
  position: relative;
  width: 960rem;
  height: calc(100vh - 93rem);
  background-color: lightslategrey;
}

.address_contacts {
  position: relative;
  width: 960rem;
  height: calc(100vh - 93rem);
  display: flex;
  flex-direction: column;
}

.address_contacts > h1 {
  position: relative;
  height: 48rem;
  top: 90rem;
  margin-bottom: 80rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 77%;
  text-transform: uppercase;
}

.address_numbers_mails_socials {
  position: relative;
  width: 488rem;
  height: 548rem;
  top: 90rem;
  
  display: flex;
  flex-direction: column;
}

.contacts_address {
  padding-bottom: 36rem;
}

.contacts_phones {
  padding: 36rem 0;
}

.contacts_mails {
  padding: 36rem 0;
}

.anmsc {
  border-bottom: 1rem solid rgba(255, 255, 255, 0.1);
}

.anmsh2 > h2 {
  height: 14rem;
  margin-bottom: 28rem;
  color: var(--Gold-Light, #d09f76);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.anmsh2 > h3,
.anmsc > a {
  text-decoration: none;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.contacts_socials {
  position: relative;
  height: 86rem;
  padding: 36rem 0;
  display: flex;
  flex-direction: row;
}

.contacts_socials > a {
  text-decoration: none;
  margin-right: 28rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (max-width: 760px) {
  .map_address_contacts_conteiner {
    position: relative;
    max-width: 760px;
    height: auto;
    background-color: rgba(13, 41, 49, 1);
  }

  .map_address_contacts {
    position: relative;
    max-width: 760rem;
    width: 375rem;
    height: 1176rem;
    top: 70rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .map_module_conteiner {
    position: relative;
    width: 375rem;
    height: 450rem;
    background-color: lightslategrey;
    order: 2;
    z-index: 10;
  }

  .address_contacts {
    position: relative;
    width: 375rem;
    height: 726rem;
    padding: 70rem 20rem;
    display: flex;
    flex-direction: column;
    order: 1;
  }

  .address_contacts > h1 {
    position: relative;
    height: 20rem;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 70rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 SemiBold';
    font-size: 26rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
  }

  .address_numbers_mails_socials {
    position: relative;
    width: 335rem;
    height: 496rem;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .contacts_address {
    padding-bottom: 24rem;
  }

  .contacts_phones {
    padding: 24rem 0;
  }

  .contacts_mails {
    padding: 24rem 0;
  }

  .anmsc {
    border-bottom: 1rem solid rgba(255, 255, 255, 0.1);
  }

  .anmsh2 > h2 {
    height: 11rem;
    margin-bottom: 20rem;
    color: var(--Gold-Light, #d09f76);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .anmsh2 > h3 {
    width: 335rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H3 Light';
    font-size: 20rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-transform: uppercase;
  }

  .contacts_socials {
    position: relative;
    height: 154rem;
    padding: 36rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contacts_socials > a {
    height: 14rem;
    text-decoration: none;
    margin-bottom: 0;
    margin-right: 0;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 90%;
  }
}
