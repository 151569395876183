.why_choose_us_conteiner {
  position: relative;
  max-width: 1920px;
  padding-top: 168rem;
  height: 1526rem;
  max-height: 1526px;
  background-color: #f5f6f8;
}

.advantages_text_conteiner {
  position: relative;
  width: 696rem;
  height: 218rem;
  top: -130rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.advantages_text_conteiner > h3 {
  height: 18rem;
  color: var(--Gold, #b88b66);
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.advantages_text_conteiner > h2 {
  height: 152rem;
  color: #212121;
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.advantages_text_conteiner > h2 > span {
  color: var(--Gold, #b88b66);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.why_choose_us {
  position: relative;
  max-width: 1920rem;
  height: 1190rem;
  display: grid;
  gap: 5rem;
  justify-content: center;
  align-content: end;
  grid-template-columns: repeat(3, 403rem);
  grid-template-rows: repeat(4, 263rem);
  grid-template-areas:
    '. . a'
    'b c d'
    '. e f'
    'g h k';
}

.why_chose_cards {
  padding: 40rem 42rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.c1 {
  grid-area: a;
}
.c2 {
  grid-area: b;
  width: 754rem;
  height: 530rem;
  justify-self: end;
  background: url('./img/899c57861bc3059fda2b87f8b96ce4e1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: lightgray;
}
.c3 {
  grid-area: c;
}
.c4 {
  grid-area: d;
  width: 754rem;
  background: url('./img/d336767092757fa3e2959c7ba35a9bba.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: lightgray;
}
.c5 {
  grid-area: e;
}
.c6 {
  grid-area: f;
}
.c7 {
  grid-area: g;
}
.c8 {
  grid-area: h;
}
.c9 {
  grid-area: k;
  padding: 0;
}

.c9 > button {
  width: 100%;
  height: 100%;
  padding: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Gold, #b88b66);
  border: none;
  backdrop-filter: blur(10rem);
  color: var(--PureWhite, #fff);
  text-align: center;
  font-family: 'Gotham Pro Bold';
  font-size: 20rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.why_chose_text_conteiner {
  width: 319rem;
  height: 183rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paragraph_svg {
  width: 319rem;
  height: 62rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.paragraph_svg > p {
  width: 253rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro Medium';
  font-size: 20rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.why_chose_text_conteiner > p {
  width: 319rem;
  height: 100rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@media (max-width: 760px) {
  .why_choose_us_conteiner {
    position: relative;
    max-width: 760px;
    padding: 72rem 20rem;
    max-height: 1759rem;
    height: 1759rem;
    background-color: #f5f6f8;
  }

  .advantages_text_conteiner {
    grid-area: a;
    position: relative;
    width: 335rem;
    height: 127rem;
    top: -80rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .advantages_text_conteiner > h3 {
    height: 14rem;
    color: var(--Gold, #b88b66);
    font-family: 'Unbounded H3 Light';
    font-size: 18rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-transform: uppercase;
    text-align: center;
  }

  .advantages_text_conteiner > h2 {
    height: 89rem;
    color: #212121;
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
  }

  .advantages_text_conteiner > h2 > span {
    color: var(--Gold, #b88b66);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .why_choose_us {
    position: relative;
    max-width: 760rem;
    height: 1615rem;
    display: grid;
    gap: 5rem;
    align-content: space-between;
    align-items: flex-end;
    grid-template-columns: repeat(1, 335rem);
    grid-template-rows: repeat(8, 206rem);
    grid-template-areas:
      'a'
      'b'
      'c'
      'e'
      'f'
      'g'
      'h'
      'k';
  }

  .why_chose_cards {
    padding: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }

  .c1 {
    grid-area: b;
  }
  .c2 {
    display: none;
  }
  .c3 {
    grid-area: c;
  }
  .c4 {
    display: none;
  }
  .c5 {
    grid-area: e;
  }
  .c6 {
    grid-area: f;
  }
  .c7 {
    grid-area: g;
  }
  .c8 {
    grid-area: h;
  }
  .c9 {
    position: relative;
    grid-area: k;
    height: 172rem;
    padding: 0;
  }

  .c9 > button {
    position: relative;
    grid-area: k;
    height: 172rem;
    top: -35rem;
    padding: 40rem 42rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--Gold, #b88b66);
    border: none;
    backdrop-filter: blur(10rem);
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Gotham Pro Bold';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .why_chose_text_conteiner {
    width: 279rem;
    height: 150rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .paragraph_svg {
    width: 279rem;
    height: 40rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .paragraph_svg > svg {
    width: 40rem;
    height: 40rem;
  }

  .paragraph_svg > p {
    width: 239rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro Medium';
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .why_chose_text_conteiner > p {
    width: 279rem;
    height: 90rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
