.previos_scroll_button {
  position: absolute;
  width: 47rem;
  height: 11rem;
  bottom: 0;
  left: 38rem;
  color: #fff;
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  transform: translateY(-535rem);
}

.presentation_text_button {
  position: absolute;
  width: 816rem;
  height: 366rem;
  bottom: 0;
  left: 338rem;
  right: 766rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(-123rem);
}

.presentation_text_button > h1 {
  height: 202rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 Light';
  font-size: 64rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-transform: uppercase;
}

.presentation_text_button > h1 > span {
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

.presentation_text_button > p {
  height: 20rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Light';
  font-size: 28rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.presentation_text_button > button {
  width: 409rem;
  height: 72rem;
  padding: 26rem 48rem;
  border-radius: 38px;
  background: var(--Gold, #b88b66);
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Bold';
  font-size: 20rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  border: none;
}

.graph_circle {
  position: absolute;
  width: 739rem;
  height: 739rem;
  bottom: 0;
  left: 1550rem;
  transform: translateY(-170rem);
}

.circle_animation {
  position: absolute;
  width: 739rem;
  height: 739rem;
  background-size: cover;
  background-image: url('./img/Без\ имени-3.png');
  transform: rotate(-0deg);
}

.active_circle {
  display: none;
}

.pagination_number_for_circle {
  position: absolute;
  width: 49rem;
  height: 113rem;
  bottom: 0;
  left: 1590rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  transform: translateY(-432rem);
}

.scroll_number {
  color: var(--PureWhite, #fff);
  text-align: right;
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  opacity: 0.5;
}

.next_scroll_button {
  position: absolute;
  width: 47rem;
  height: 11rem;
  bottom: 0;
  left: 1833rem;
  color: #fff;
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  transform: translateY(-535rem);
}

@media (max-width: 760px) {
  .previos_scroll_button {
    display: none;
  }

  .presentation_text_button {
    position: absolute;
    width: 334rem;
    height: 232rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(-122rem);
  }

  .presentation_text_button > h1 {
    height: 82rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 SemiBold';
    font-size: 26rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
  }

  .presentation_text_button > h1 > span {
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 SemiBold';
    font-size: 26rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
  }

  .presentation_text_button > p {
    height: 38rem;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Light';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
  }

  .presentation_text_button > button {
    width: 334rem;
    height: 56rem;
    padding: 16rem 48rem;
    border-radius: 38px;
    background: var(--Gold, #b88b66);
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Bold';
    font-size: 14rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    border: none;
  }

  .graph_circle {
    display: none;
  }

  .circle_animation {
    display: none;
  }

  .active_circle {
    display: none;
  }

  .pagination_number_for_circle {
    position: absolute;
    width: 160rem;
    height: 11rem;
    bottom: 0;
    left: 0;
    right: 0;
    bottom: 64rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transform: translateY(0rem);
  }

  .scroll_number {
    color: var(--PureWhite, #fff);
    text-align: right;
    font-family: 'Gotham Pro Light';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    opacity: 0.5;
  }

  .next_scroll_button {
    display: none;
  }
}
