.presentation_hammam_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1777rem;
  max-height: 1777px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    linear-gradient(180deg, rgba(17, 59, 71, 0) 20.29%, #113b47 82.3%),
    url('./img/124ed2b08b8192ea50e179ceca2a70d2.png');
  background-color: #f5f6f8;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.bread_crumbs {
  position: absolute;
  width: 143rem;
  height: 11rem;
  top: 124rem;
  left: 21.82%;
  right: 76.93%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bread_crumbs > p {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.presentation_hammam_text {
  position: absolute;
  width: 1224rem;
  height: 358rem;
  top: 20.1%;
  left: 17.9%;
  right: 18.33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.presentation_hammam_text > h1 {
  height: 144rem;
  color: var(--PureWhite, #fff);
  text-align: left;
  font-family: 'Unbounded H3 Extra Light';
  font-size: 192rem;
  font-style: normal;
  font-weight: 200;
  line-height: 75%;
  text-transform: uppercase;
}

.presentation_hammam_text > h2 {
  height: 48rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 Light';
  font-size: 64rem;
  font-style: normal;
  font-weight: 300;
  line-height: 75%;
  text-transform: uppercase;
}

.presentation_hammam_text > p {
  width: 592rem;
  height: 70rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.presentation_hammam_img {
  position: absolute;
  width: 1216rem;
  height: 660rem;
  top: 52.27%;
  left: 352rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hammam_img {
  background-color: #f5f6f8;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.presentation_hammam_img_1 {
  width: 280rem;
  height: 320rem;
  align-self: flex-end;
  background-image: url('./img/290076769460183eb235c0ca0ef3c558.png');
}
.presentation_hammam_img_2 {
  width: 592rem;
  height: 660rem;
  background-image: url('./img/d336767092757fa3e2959c7ba35a9bba.png');
}
.presentation_hammam_img_3 {
  width: 280rem;
  height: 320rem;
  background-image: url('./img/438b7cc836d976c97b6ca37c82ed43f6.png');
}

@media (max-width: 760px) {
  .presentation_hammam_conteiner {
    position: relative;
    max-width: 760px;
    height: 700rem;
    max-height: 700rem;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 20.29%, #113b47 82.3%),
      url('./img/124ed2b08b8192ea50e179ceca2a70d2.png');
    background-color: #f5f6f8;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .bread_crumbs {
    display: none;
  }

  .bread_crumbs > p {
    display: none;
  }

  .presentation_hammam_text {
    position: relative;
    width: 335rem;
    height: 216rem;
    top: 193rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .presentation_hammam_text > h1 {
    height: 82rem;
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Unbounded H3 Extra Light';
    font-size: 26rem;
    font-style: normal;
    font-weight: 200;
    line-height: 120%;
    text-transform: uppercase;
  }

  .presentation_hammam_text > h1::after {
    content: ' - от проекта до реализации';
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Unbounded H3 Extra Light';
    font-size: 26rem;
    font-style: normal;
    font-weight: 200;
    line-height: 120%;
    text-transform: uppercase;
  }

  .presentation_hammam_text > h2 {
    display: none;
  }

  .presentation_hammam_text > p {
    width: 335rem;
    height: 106rem;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Light';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
  }

  .presentation_hammam_img {
    position: relative;
    width: 335rem;
    height: 181rem;
    top: 250rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .hammam_img {
    background-color: #f5f6f8;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .presentation_hammam_img_1 {
    width: 77rem;
    height: 87rem;
    align-self: flex-end;
    background-image: url('./img/290076769460183eb235c0ca0ef3c558.png');
  }
  .presentation_hammam_img_2 {
    width: 163rem;
    height: 180rem;
    background-image: url('./img/d336767092757fa3e2959c7ba35a9bba.png');
  }
  .presentation_hammam_img_3 {
    width: 77rem;
    height: 87rem;
    background-image: url('./img/438b7cc836d976c97b6ca37c82ed43f6.png');
  }
}
