.header_conteiner {
  position: fixed;
  min-width: 100%;
  max-width: 1920px;
  height: 92rem;
  margin: 0 auto;
  padding: 20rem 32rem;
  border-bottom: 1rem solid rgba(255, 255, 255, 0.1);
  background: var(--Background, rgba(13, 41, 49, 0));
  backdrop-filter: blur(10px);
  z-index: 10;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header_nav_menu_socials {
  width: 301rem;
  height: 52rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.socials {
  width: 108rem;
  height: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.socials > svg {
  width: 20rem;
  height: 20rem;
}

.header_contacts_info {
  width: 301rem;
  height: 33rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tel_contact {
  text-decoration: none;
  color: var(--PureWhite, #fff);
  text-align: right;
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.mail_contact {
  color: var(--PureWhite, #fff);
  text-align: right;
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 760px) {
  .header_conteiner {
    position: fixed;
    max-width: 760px;
    width: 100%;
    height: 80rem;
    padding: 16rem 20rem;
    border-bottom: 1rem solid rgba(255, 255, 255, 0.1);
    background: var(--Background, rgba(13, 41, 49, 0));
    backdrop-filter: blur(10px);
    z-index: 10;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header_nav_menu_socials {
    height: 48rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header_logo {
    margin-right: 122rem;
  }

  .header_logo > a > svg {
    width: 98rem;
    height: 37rem;
  }

  .socials {
    display: none;
  }

  .socials > svg {
    display: none;
  }

  .header_contacts_info {
    display: none;
  }

  .tel_contact {
    color: var(--PureWhite, #fff);
    text-align: right;
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .mail_contact {
    color: var(--PureWhite, #fff);
    text-align: right;
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}
