.number_of_finished_works_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1506rem;
  max-height: 1506px;
  background-color: var(--Background, #0d2931);
}

.number_of_finished_works {
  position: relative;
  max-width: 1920rem;
  height: 1506rem;
  margin: 0 auto;
}

.number_finished_works {
  position: absolute;
  width: 592rem;
  height: 244rem;
  display: flex;
  bottom: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  transform: translate(982rem, -1084rem);
}

.number_finished_works > h3 {
  width: 495rem;
  color: var(--River, #83bdcd);
  text-align: right;
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.number_finished_works > p {
  color: var(--PureWhite, #fff);
  text-align: right;
  font-family: 'Unbounded H3 Extra Light';
  font-size: 192rem;
  font-style: normal;
  font-weight: 200;
  line-height: 130%;
  text-transform: uppercase;
}

.design_img_finished_works {
  position: absolute;
  width: 742rem;
  height: 490rem;
  bottom: 0;
  left: 0;
  background: url('./img/7906ba0c0d2efe5766c7bc42e46c9a04.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: lightgray;
  z-index: 5;
  transform: translateY(-782rem);
}

.paragraph_finished_works {
  position: absolute;
  width: 1920rem;
  height: 900rem;
  bottom: 0;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url('./img/13f00c68d855071e65cbbdfae2712ff5.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: lightgray;
}

.paragraph_finished_works_conteiner {
  position: absolute;
  width: 904rem;
  height: 302rem;
  top: 333rem;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(667rem);
}

.paragraph_finished_works_conteiner > h3 {
  width: 794rem;
  height: 52rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.paragraph_finished_works_conteiner > h2 {
  width: 904rem;
  height: 202rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

@media (max-width: 760px) {
  .number_of_finished_works_conteiner {
    position: relative;
    max-width: 760px;
    height: 920rem;
    max-height: 920rem;
    background-color: var(--Background, #0d2931);
  }

  .number_of_finished_works {
    position: relative;
    max-width: 760rem;
    height: 920rem;
    margin: 0 auto;
  }

  .number_finished_works {
    position: absolute;
    width: 330rem;
    height: 136rem;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    transform: translate(0, 0);
  }

  .number_finished_works > h3 {
    width: 330rem;
    height: 39rem;
    color: var(--River, #83bdcd);
    text-align: right;
    font-family: 'Unbounded H3 Light';
    font-size: 18rem;
    font-style: normal;
    font-weight: 300;
    line-height: 124%;
    text-transform: uppercase;
  }

  .number_finished_works > p {
    width: 330rem;
    height: 69rem;
    color: var(--PureWhite, #fff);
    text-align: right;
    font-family: 'Unbounded H3 Extra Light';
    font-size: 92rem;
    font-style: normal;
    font-weight: 200;
    line-height: 76%;
    text-transform: uppercase;
  }

  .design_img_finished_works {
    position: absolute;
    width: 275rem;
    height: 181rem;
    bottom: -219rem;
    left: 4rem;
    background: url('./img/7906ba0c0d2efe5766c7bc42e46c9a04.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: lightgray;
    z-index: 5;
    transform: translateY(-782rem);
  }

  .paragraph_finished_works {
    position: absolute;
    width: 376rem;
    height: 347rem;
    bottom: 307rem;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url('./img/13f00c68d855071e65cbbdfae2712ff5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: lightgray;
  }

  .paragraph_finished_works_conteiner {
    position: absolute;
    width: 335rem;
    height: 195rem;
    top: 387rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateX(0);
  }

  .paragraph_finished_works_conteiner > h3 {
    width: 335rem;
    height: 89rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H3 Light';
    font-size: 18rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    text-transform: uppercase;
  }

  .paragraph_finished_works_conteiner > h2 {
    width: 335rem;
    height: 82rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 SemiBold';
    font-size: 26rem;
    font-style: normal;
    font-weight: 600;
    line-height: 112%;
    text-transform: uppercase;
  }
}
