.design_stages_slider_conteiner {
  position: relative;
  width: 1920rem;
  height: 920rem;
  background-color: #f5f6f8;
}

.design_stages_slider_img {
  position: absolute;
  width: 736rem;
  height: 977rem;
  top: -57rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #f5f6f8;
  z-index: 6;
}

.design_stages_slider_paragraph {
  position: absolute;
  width: 618rem;
  height: 94rem;
  left: 45.52%;
  right: 22.3%;
  top: 154rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 6;
}

.design_stages_slider_paragraph :first-child {
  height: 36rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  align-self: flex-start;
}

.design_stages_slider_paragraph :last-child {
  height: 36rem;
  color: var(--Pure-Black, #b88b66);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  align-self: flex-start;
}

.design_stages_slider_buttons {
  position: relative;
  width: 1840rem;
  height: 494rem;
  top: 395rem;
  left: 18.33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 6;
}

.stages_slider {
  position: relative;
  width: 3808rem;
  height: 397rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.5s;
}

.design_stages_buttons {
  width: 120rem;
  height: 52rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.design_stages_card_conteiner {
  width: 608rem;
  height: 397rem;
  padding: 72rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}

.design_stages_data_text {
  width: 496rem;
  height: 194rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.design_stages_data_text > h1 {
  height: 36rem;
  margin-bottom: 70rem;
  color: var(--Gold, #b88b66);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.design_stages_data_text > h2 {
  height: 20rem;
  margin-bottom: 28rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 28rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.design_stages_data_text > p {
  width: 464rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.design_stages_buttons {
  width: 120rem;
  height: 52rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-start;
}

.buttons_design_stages {
  display: flex;
  width: 52rem;
  height: 52rem;
  padding: 5rem 16rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 48rem;
  border: 1rem solid rgba(255, 255, 255, 0.1);
  background: #fff;
}

.buttons_design_stages > svg {
  width: 24rem;
  height: 24rem;
}

@media (max-width: 760px) {
  .design_stages_slider_conteiner {
    position: relative;
    width: 335rem;
    height: 1076rem;
    margin: auto;
    background-color: #f5f6f8;
  }

  .design_stages_slider_img {
    display: none;
  }

  .design_stages_slider_paragraph {
    position: relative;
    width: 335rem;
    height: 56rem;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 6;
  }

  .design_stages_slider_paragraph :first-child {
    height: 20rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Unbounded H2 Regular';
    font-size: 26rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    align-self: center;
  }

  .design_stages_slider_paragraph :last-child {
    height: 20rem;
    color: var(--Pure-Black, #b88b66);
    font-family: 'Unbounded H2 Regular';
    font-size: 26rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    align-self: center;
  }

  .design_stages_slider_buttons {
    position: relative;
    width: 335rem;
    height: 996rem;
    top: 24rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 6;
  }

  .stages_slider {
    position: relative;
    width: 335rem;
    height: 996rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.5s;
  }

  .design_stages_buttons {
    display: none;
  }

  .design_stages_card_conteiner {
    width: 335rem;
    height: 246rem;
    padding: 32rem 24rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
  }

  .design_stages_data_text {
    width: 287rem;
    height: 182rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
  }

  .design_stages_data_text > h1 {
    height: 21rem;
    margin-bottom: 20rem;
    color: var(--Gold, #b88b66);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .design_stages_data_text > h2 {
    height: 11rem;
    margin-bottom: 20rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .design_stages_data_text > p {
    width: 287rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .design_stages_buttons {
    display: none;
  }

  .buttons_design_stages {
    display: none;
  }

  .buttons_design_stages > svg {
    display: none;
  }
}
