.about_composite_material_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1250rem;
  max-height: 1250px;
  padding: 65rem 0 0 0;
  background-color: #0d2931;
}

.about_composite_material_conteiner > h2 {
  position: absolute;
  width: 904rem;
  height: 326rem;
  top: 223rem;
  left: 34.9%;
  right: 18.65%;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  z-index: 3;
}

.composite_ocean_view_pool {
  position: absolute;
  width: 1048rem;
  height: 840rem;
  top: 410rem;
  background: url('./img/5fcad85c111c38bf61cadf087707ef7b.png');
  background-color: #f5f6f8;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.about_composite_material_conteiner > p {
  position: absolute;
  width: 384rem;
  height: 155rem;
  top: 665rem;
  left: 62%;
  right: 18.65%;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  z-index: 1;
}

.about_composite_material_conteiner > img {
  position: absolute;
  z-index: 0;
}

@media (max-width: 760px) {
  .about_composite_material_conteiner {
    position: relative;
    max-width: 760px;
    height: 742rem;
    max-height: 742rem;
    padding: 72rem 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #0d2931;
  }

  .about_composite_material_conteiner > h2 {
    position: relative;
    width: 335rem;
    height: 183rem;
    top: 0rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H2 Regular';
    font-size: 20rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    z-index: 3;
    order: 1;
  }

  .composite_ocean_view_pool {
    position: relative;
    width: 335rem;
    height: 217rem;
    top: 0;
    background: url('./img/5fcad85c111c38bf61cadf087707ef7b.png');
    background-color: #f5f6f8;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    order: 3;
  }

  .about_composite_material_conteiner > p {
    position: relative;
    width: 335rem;
    height: 150rem;
    top: 0;
    left: 0;
    right: 0;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Light';
    font-size: 14rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    z-index: 1;
    order: 2;
  }

  .about_composite_material_conteiner > img {
    display: none;
  }
}
