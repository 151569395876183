.privacy_policy_conteiner {
  position: relative;
  max-width: 1920px;
  height: auto;
  margin: 0 auto;
  background-color: #0d2931;
}

@media (max-width: 760px) {
  .privacy_policy_conteiner {
    position: relative;
    max-width: 760px;
    height: auto;
    margin: 0 auto;
    background-color: #0d2931;
  }
}
