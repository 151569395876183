.privacy_policy_reference_text_conteiner {
  position: relative;
  max-width: 1920px;
  height: 3445rem;
  max-height: 3445px;
  background-color: #0d2931;
}

.privacy_policy_reference_text {
  position: relative;
  max-width: 1920px;
  width: 1920rem;
  height: 3445rem;
  top: 92rem;
  padding: 168rem 353rem 193rem 353rem;
  background-color: rgba(255, 255, 255, 1);
}

.bread_crumbs {
  position: absolute;
  width: 143rem;
  height: 11rem;
  top: 124rem;
  left: 21.82%;
  right: 76.93%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bread_crumbs > p {
  color: var(--PureWhite, rgba(33, 33, 33, 1));
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.privacy_policy_reference_text > h1 {
  width: 1088rem;
  margin-bottom: 106rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

.privacy_policy_text {
  position: relative;
  width: 800rem;
  height: 2853rem;
  left: 415rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

section {
  position: relative;
  width: 800rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main_provisions {
  height: 365rem;
}

.main_provisions > p {
  height: 141rem;
}

.basic_concepts {
  height: 1369rem;
}

.basic_concepts > p {
  height: 1327rem;
}

.basic_rights_and_obligations {
  height: 975rem;
}

.basic_rights_and_obligations > p {
  height: 933rem;
}

section > h3 {
  height: 14rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro Bold';
  font-size: 20rem;
  font-style: normal;
  font-weight: 700;
  line-height: 80%;
}

section > h4 {
  height: 154rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

section > p {
  color: var(--Pure-Black, #212121);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 134%;
}

@media (max-width: 760px) {
  .privacy_policy_reference_text_conteiner {
    position: relative;
    max-width: 760px;
    height: auto;
    top: 80rem;
    max-height: none;
    background-color: rgba(255, 255, 255, 1);
  }

  .privacy_policy_reference_text {
    position: relative;
    max-width: 760px;
    width: 375rem;
    height: 4700rem;
    top: 0;
    padding: 72rem 20rem 0 20rem;
    background-color: rgba(255, 255, 255, 1);
  }

  .bread_crumbs {
    display: none;
  }

  .bread_crumbs > p {
    display: none;
  }

  .privacy_policy_reference_text > h1 {
    width: 335rem;
    margin-bottom: 72rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Unbounded H1 SemiBold';
    font-size: 15rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
  }

  .privacy_policy_text {
    position: relative;
    width: 335rem;
    height: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  section {
    position: relative;
    width: 335rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main_provisions {
    height: auto;
  }

  .main_provisions > p {
    height: auto;
  }

  .basic_concepts {
    height: auto;
  }

  .basic_concepts > p {
    height: auto;
  }

  .basic_rights_and_obligations {
    height: auto;
  }

  .basic_rights_and_obligations > p {
    height: auto;
  }

  section > h3 {
    height: auto;
    margin-bottom: 28rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro Bold';
    font-size: 16rem;
    font-style: normal;
    font-weight: 700;
    line-height: 80%;
  }

  section > h4 {
    height: 253rem;
    margin-bottom: 28rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }

  section > p {
    margin-bottom: 72rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 134%;
  }
}
