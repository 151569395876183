.presentation_slider_block {
  position: relative;
  max-width: 1920px;
  height: 100vh;
  max-height: 1080px;
  overflow: hidden;
}

.slider_swiper_conteiner {
  position: relative;
  max-width: 1920px;
  height: 100vh;
  max-height: 1080px;
  background-color: #113b47;
}

.mySwiper {
  z-index: 0 !important;
}

.sss1 {
  width: 100vw;
  height: 100vh;
}

@media (max-width: 760px) {
  .presentation_slider_block {
    position: relative;
    max-width: 760px;
    height: 100vh;
    max-height: 820px;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%),
      url('/src/pages/gran_gota_main/block_1_presentation_slider_block/img/35ad5d0ca644f5c87ac44fe98904e426.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: lightgray;
    overflow: hidden;
  }

  .slider_swiper_conteiner {
    position: relative;
    max-width: 760px;
    height: 100vh;
    background-color: #113b47;
  }

  .mySwiper {
    z-index: 0 !important;
  }

  .sss1 {
    width: 100vw;
    height: 100vh;
  }
}
