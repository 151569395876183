.msg_not_found_conteiner {
    position: relative;
    max-width: 1920px;
    width: 1920rem;
    height: 800rem;
    padding: 200rem 90rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #0d2931;;
}

.msg_not_found_conteiner > h2, h3 {
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 Light';
  font-size: 64rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-transform: uppercase;
}

.msg_not_found_conteiner > h3 {
    align-self: flex-end;
}

.msg_not_found_conteiner > h3 > a {
    color: white;
}

@media (max-width: 760px) {
    .msg_not_found_conteiner {
        position: relative;
        max-width: 760px;
        width: 375rem;
        height: 400rem;
        padding: 80rem 30rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #0d2931;;
    }
    
    .msg_not_found_conteiner > h2, h3 {
      color: var(--PureWhite, #fff);
      font-family: 'Unbounded H1 Light';
      font-size: 18rem;
      font-style: normal;
      font-weight: 300;
      line-height: 120%;
      text-transform: uppercase;
    }
    
    .msg_not_found_conteiner > h3 {
        align-self: flex-end;
    }
    
    .msg_not_found_conteiner > h3 > a {
        color: white;
    }
}