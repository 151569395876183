.form_footer {
  width: 1216rem;
  height: 558rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.application_form_button {
  width: 688rem;
  height: 321rem;
  margin: auto 72rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.form_footer_img {
  width: 384rem;
  height: 558rem;
  background-color: #f5f6f8;
  background: url('./img/0ba4b98040a8feda5577451a9d616876.png');
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.application_text {
  height: 97rem;
  width: 688rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.application_text :first-child {
  height: 36rem;
  color: var(--Pure-Black, #212121);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75;
  text-transform: uppercase;
}

.application_text :last-child {
  height: 36rem;
  color: var(--Grey, #464646);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}

.form_input {
  height: 72rem;
}

.PopUpFormCopy, .FooterFormCopy {
  width: 688rem;
  height: 72rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button_send_privacy_policy {
  height: 72rem;
}

.input {
  width: 334rem;
  height: 72rem;
  padding: 35rem 26.5rem;
  border-style: none;
  border-radius: 41rem;
  border: 2rem solid var(--Light-Grey, #f5f6f8);
  background: var(--Light-Grey, #f5f6f8);
  outline: none;
}

.input:focus {
  border: 2rem solid var(--Gold, #b88b66);
}

.input_name_form {
  font-family: 'Gotham Pro';
  font-size: 18rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.input_tel_form_conteiner {
  width: 334rem;
  height: 72rem;
  display: flex;
  flex-direction: row;
}

.defCountry {
  position: absolute;
  width: 20rem;
  height: 72rem;
  margin: 1rem 0 0 26.5rem;
  font-family: 'Gotham Pro';
  font-size: 18rem;
  font-style: normal;
  font-weight: 500;
  line-height: 72rem;
}

input {
  width: 334rem;
  height: 72rem;
  padding: 35rem 26.5rem 35rem 50rem;
  border-style: none;
  border-radius: 41rem;
  border: 2rem solid var(--Light-Grey, #f5f6f8);
  background: var(--Light-Grey, #f5f6f8);
  outline: none;
}

.input_tel {
  font-family: 'Gotham Pro';
  font-size: 18rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.button_send_privacy_policy {
  width: 688rem;
  height: 72rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button_send_privacy_policy > input {
  width: 230rem;
  height: 72rem;
  padding: 8rem;
  border-radius: 38rem;
  border-style: none;
  background: var(--Gold, #b88b66);
  outline: none;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Bold';
  font-size: 14rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24rem */
  text-transform: uppercase;
}

.button_send_privacy_policy > p {
  width: 422rem;
  height: 33rem;
  color: var(--Grey, #464646);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
}

.button_send_privacy_policy > p > a {
  text-decoration: none;
  color: var(--Gold, #b88b66);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
}

.reCAPTCHA_branding_visibly {
  position: absolute;
  margin-top: 470rem;
  margin-right: 455rem;
  width: 688rem;
  height: 72rem;
  color: var(--Pure-Black, #000);
  font-family: 'Gotham Pro';
  font-size: 14rem;
  font-style: normal;
  font-weight: 500;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (max-width: 760px) {
  .form_footer {
    width: 335rem;
    height: 515rem;
    padding: 42rem 24rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .application_form_button {
    width: 287rem;
    height: 431rem;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }

  .form_footer_img {
    display: none;
  }

  .application_text {
    width: 287rem;
    height: 141rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .application_text :first-child {
    height: 55rem;
    color: var(--Pure-Black, #212121);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
  }

  .application_text :last-child {
    height: 70rem;
    color: var(--Grey, #464646);
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    text-align: center;
  }

  .form_input {
    width: 287rem;
    height: 120rem;
  }

  .PopUpFormCopy, .FooterFormCopy {
    width: 287rem;
    height: 120rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .button_send_privacy_policy {
    height: 72rem;
  }

  .input {
    width: 287rem;
    height: 56rem;
    padding: 23rem 28rem;
    border-style: none;
    border-radius: 41rem;
    border: 2rem solid var(--Light-Grey, #f5f6f8);
    background: var(--Light-Grey, #f5f6f8);
    outline: none;
  }

  .input:focus {
    border: 2rem solid var(--Gold, #b88b66);
  }

  .input_name_form {
    font-family: 'Gotham Pro';
    font-size: 18rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .input_tel_form_conteiner {
    width: 287rem;
    height: 56rem;
    display: flex;
    flex-direction: row;
  }

  .input_tel {
    font-family: 'Gotham Pro';
    font-size: 18rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .button_send_privacy_policy {
    width: 287rem;
    height: 122rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .button_send_privacy_policy > input {
    width: 287rem;
    height: 56rem;
    padding: 16rem 48rem;
    border-radius: 38rem;
    border-style: none;
    background: var(--Gold, #b88b66);
    outline: none;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Bold';
    font-size: 14rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24rem */
    text-transform: uppercase;
    order: 2;
  }

  .button_send_privacy_policy > p {
    width: 287rem;
    height: 50rem;
    color: var(--Grey, #464646);
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    text-align: center;
    order: 1;
  }

  .button_send_privacy_policy > p > a {
    text-decoration: none;
    color: var(--Gold, #b88b66);
    font-family: 'Gotham Pro Bold';
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
  }

  .reCAPTCHA_branding_visibly {
    position: absolute;
    margin-top: 440rem;
    margin-right: 0rem;
    width: 287rem;
    height: 22rem;
    color: var(--Pure-Black, #000);
    font-family: 'Gotham Pro';
    font-size: 8rem;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}
