.design_stages_hammam_conteiner {
  position: relative;
  max-width: 1920px;
  height: 977rem;
  max-height: 977px;
  padding-top: 57rem;
  background-color: #0d2931;
  overflow-x: hidden;
}

@media (max-width: 760px) {
  .design_stages_hammam_conteiner {
    position: relative;
    max-width: 760px;
    height: 1220rem;
    max-height: 1220rem;
    padding: 72rem 20rem;
    background-color: rgb(245, 246, 248);
    overflow-x: hidden;
  }
}
