.slider-container {
  position: relative;
  max-width: 1920px;
  width: 1920rem;
  height: 688rem;
  margin: 0 auto;
}

.slick-slider {
  position: relative;
  width: auto;
  height: 688rem;
  margin: 0 auto;
}

.slick-list {
  position: relative;
  width: auto;
  height: 600rem;
  z-index: 1;
}

.slick-track {
  position: relative;
  width: auto;
  height: 600rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slick-slide {
  position: relative;
  height: 600rem;
}

.slick-slide > div {
  position: relative;
  width: 1040rem;
  height: 600rem;
  padding: 0 16rem;
}

.slick-slide > div > img {
  position: relative;
  height: 600rem;
}

.sscbp {
  position: absolute;
  width: 52rem;
  height: 52rem;
  padding: 14rem 11rem;
  border-radius: 52rem;
  border: none;
  left: -1030rem;
  top: 270rem;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}

.sscbp > svg {
  width: 24rem;
  height: 24rem;
}

.sscbn {
  position: absolute;
  width: 52rem;
  height: 52rem;
  padding: 14rem 17rem;
  border-radius: 52rem;
  border: none;
  left: 1000rem;
  top: 270rem;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}

.sscbn > svg {
  width: 24rem;
  height: 24rem;
}

.slick-dots {
  position: relative;
  width: auto;
  height: 16rem;
  margin: 0 auto;
  top: 70rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots > li {
  position: relative;
  width: 16rem;
  height: 16rem;
  margin: 0 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots > li > button {
  position: relative;
  width: 16rem;
  height: 16rem;
  border: none;
  border-radius: 16rem;
  background: rgb(131, 189, 205);
  opacity: 0.5;
  font-size: 0;
}

.slick-active > button {
  background: rgb(131, 189, 205);
  opacity: 1 !important;
}

@media (max-width: 760px) {
  .slider-container {
    position: relative;
    max-width: 760px;
    width: 335rem;
    height: 277rem;
    margin: 0 auto;
  }

  .slick-slider {
    position: relative;
    width: auto;
    height: 277rem;
    margin: 0 auto;
  }

  .slick-list {
    position: relative;
    width: auto;
    height: 192rem;
    z-index: 1;
  }

  .slick-track {
    position: relative;
    width: auto;
    height: 192rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .slick-slide {
    position: relative;
    height: 192rem;
  }

  .slick-slide > div {
    position: relative;
    width: 335rem;
    height: 192rem;
    padding: 0 5rem;
  }

  .slick-slide > div > img {
    position: relative;
    height: 192rem;
  }

  .sscbp {
    position: absolute;
    width: 52rem;
    height: 52rem;
    padding: 14rem 11rem;
    border-radius: 52rem;
    border: none;
    left: -80rem;
    top: 224rem;
    right: 0;
    margin: 0 auto;
    z-index: 2;
  }

  .sscbp > svg {
    width: 24rem;
    height: 24rem;
  }

  .sscbn {
    position: absolute;
    width: 52rem;
    height: 52rem;
    padding: 14rem 17rem;
    border-radius: 52rem;
    border: none;
    left: 80rem;
    top: 224rem;
    right: 0;
    margin: 0 auto;
    z-index: 2;
  }

  .sscbn > svg {
    width: 24rem;
    height: 24rem;
  }

  .slick-dots {
    display: none;
  }

  .slick-dots > li {
    display: none;
  }

  .slick-dots > li > button {
    display: none;
  }

  .slick-active > button {
    display: none;
  }
}
