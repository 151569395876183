.office_photo_conteiner {
    position: relative;
    max-width: 1920px;
    width: 1920rem;
    height: 1200rem;
    padding: 20rem 0;
    overflow: hidden;
}

.office_photo_parallax {
    position: relative;
    max-width: 1920px;
    height: 1200rem;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./data/img/office_photo.jpg');
    background-color: #113b47;
}

@media (max-width:760px) {
    .office_photo_conteiner {
        position: relative;
        max-width: 760rem;
        width: 375rem;
        height: 500rem;
        padding: 20rem 0;
        overflow: hidden;
    }

    .office_photo_conteiner > div {
        max-width: 760rem;
        width: 375rem;
    }
    
    .office_photo_parallax {
        position: relative;
        max-width: 760rem;
        height: 500rem;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('./data/img/office_photo.jpg');
        background-color: #113b47;
    }
}