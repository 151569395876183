.about_concrete_material_conteiner {
  position: relative;
  max-width: 1920px;
  max-height: 1250px;
  height: 1250rem;
  padding: 65rem 0 0 0;
  background-color: #0d2931;
}

.about_concrete_material_conteiner > h2 {
  position: absolute;
  width: 904rem;
  height: 326rem;
  top: 223rem;
  left: 18.75%;
  right: 34.38%;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  z-index: 3;
}

.ocean_view_pool {
  position: absolute;
  width: 1048rem;
  height: 840rem;
  top: 410rem;
  right: 0;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url('./img/7a8efbb6e35b8e78d64c5c6008281c77.png');
  background-color: #f5f6f8;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.about_concrete_material_conteiner > p {
  position: absolute;
  width: 384rem;
  height: 155rem;
  top: 665rem;
  left: 18.65%;
  right: 62%;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  z-index: 1;
}

.about_concrete_material_conteiner > img {
  position: absolute;
  z-index: 0;
}

@media (max-width: 760px) {
  .about_concrete_material_conteiner {
    position: relative;
    max-width: 760px;
    max-height: 714rem;
    height: 714rem;
    padding: 72rem 20rem;
    background-color: #0d2931;
  }

  .about_concrete_material_conteiner > h2 {
    position: relative;
    width: 335rem;
    height: 135rem;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H2 Regular';
    font-size: 20rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    z-index: 3;
  }

  .ocean_view_pool {
    position: absolute;
    width: 335rem;
    height: 217rem;
    top: 425rem;
    right: 0;
    left: 0;
    margin: 0 auto;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      url('./img/7a8efbb6e35b8e78d64c5c6008281c77.png');
    background-color: #f5f6f8;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .about_concrete_material_conteiner > p {
    position: relative;
    width: 335rem;
    height: 170rem;
    top: 34rem;
    left: 0;
    right: 0;
    margin: auto;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Light';
    font-size: 14rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    z-index: 1;
  }

  .about_concrete_material_conteiner > img {
    display: none;
  }
}
