.types_of_hammams_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1312rem;
  max-height: 1312px;
  padding: 168rem 0;
  background: linear-gradient(
      180deg,
      rgba(13, 41, 49, 0.5) 15.37%,
      #0d2931 100%
    ),
    url('./img/1ffa8e601179865c59e62f47b6188058.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #f5f6f8;
  background-size: cover;
}

.types_of_hammams {
  position: relative;
  width: 1216rem;
  height: 976rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.types_of_hammams > h2 {
  height: 36rem;
  color: var(--PureWhite, #fff);
  text-align: center;
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.choose_types_of_hammams_buttons {
  width: 1216rem;
  height: 72rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.choose_types_of_hammams_buttons > button {
  width: 598rem;
  height: 72rem;
  padding: 32rem 74rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 70rem;
  border: 1rem solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5rem);
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.choose_types_of_hammams_buttons > button:focus {
  background: white;
  color: black;
}

.types_of_hammams_img {
  width: 1216rem;
  height: 470rem;
}

.tohi {
  position: absolute;
  width: 460rem;
  height: 470rem;
  border-radius: 470rem;
  border: 10rem solid #fff;
  background-color: #f5f6f8;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.type_of_hammam_img_2 {
  margin-left: 350rem;
}
.type_of_hammam_img_3 {
  margin-left: 750rem;
}

.types_of_hammams > p {
  width: 578rem;
  height: 182rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  align-self: flex-end;
}

@media (max-width: 760px) {
  .types_of_hammams_conteiner {
    position: relative;
    max-width: 760px;
    height: 658rem;
    max-height: 658rem;
    padding: 0;
    background: linear-gradient(
        180deg,
        rgba(13, 41, 49, 0.5) 15.37%,
        #0d2931 100%
      ),
      url('./img/1ffa8e601179865c59e62f47b6188058.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #f5f6f8;
    background-size: cover;
  }

  .types_of_hammams {
    position: relative;
    width: 335rem;
    height: 508rem;
    top: 60rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .types_of_hammams > h2 {
    height: 58rem;
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .choose_types_of_hammams_buttons {
    position: relative;
    width: 335rem;
    height: 56rem;
    top: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .choose_types_of_hammams_buttons > button {
    width: 162.5rem;
    height: 56rem;
    padding: 32rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 70rem;
    border: 1rem solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5rem);
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 12rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }

  .types_of_hammams_img {
    position: relative;
    top: 40rem;
    width: 335rem;
    height: 134rem;
  }

  .tohi {
    position: absolute;
    width: 125rem;
    height: 125rem;
    border-radius: 125rem;
    border: 2rem solid #fff;
    background-color: #f5f6f8;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }

  .type_of_hammam_img_2 {
    margin-left: 106rem;
  }
  .type_of_hammam_img_3 {
    margin-left: 212rem;
  }

  .types_of_hammams > p {
    position: relative;
    width: 335rem;
    height: 163rem;
    top: 40rem;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    align-self: center;
    text-align: center;
  }
}
