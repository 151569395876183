.temporary_conteiner_slider_pools {
  position: relative;
  max-width: 1920px;
  width: 1920rem;
  height: 964rem;
  padding: 168rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(13, 41, 49);
}

.temporary_conteiner_slider_pools > h2 {
  position: relative;
  width: auto;
  height: 36rem;
  color: rgb(255, 255, 255);
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 760px) {
  .temporary_conteiner_slider_pools {
    position: relative;
    max-width: 760px;
    width: 375rem;
    height: 330rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(13, 41, 49);
    overflow: hidden;
  }

  .temporary_conteiner_slider_pools > h2 {
    position: relative;
    width: auto;
    height: 21rem;
    color: rgb(255, 255, 255);
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0%;
    text-align: center;
    text-transform: uppercase;
  }
}
