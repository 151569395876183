.footer_conteiner {
  position: relative;
  width: 100%;
  height: 1388rem;
  max-height: 1388px;
  flex-shrink: 0;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    linear-gradient(0deg, rgba(17, 59, 71, 0.52) 0%, #113b47 100%),
    url('./img/d61b45f6b8106e73f9fb3719e97d69d6.png') #133741;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: lightgray;
  overflow: hidden;
}

.footer {
  width: 100%;
  height: 1388rem;
}

.form_footer_conteiner {
  width: 1216rem;
  height: 558rem;
  margin: 265rem auto auto auto;
  background-color: #fff;
}

.logo_footer {
  width: 89rem;
  height: 155rem;
  margin: 121rem auto auto auto;
  flex-shrink: 0;
}

.adress_contacts_social {
  max-width: 1211rem;
  height: 79rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 51rem auto auto auto;
}

.adress_map_footer {
  width: 260rem;
  height: 79rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.adress_map_footer > p {
  width: 199rem;
  height: 42rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.adress_map_footer > a {
  height: 14rem;
  text-decoration: none;
  color: var(--Gold, #b88b66);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.tel_mail_footer {
  width: 271rem;
  height: 48rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.tel_mail_footer :first-child {
  height: 18rem;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.tel_mail_footer :last-child {
  height: 14rem;
  text-decoration: none;
  color: #fff;
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.social_footer {
  width: 260rem;
  height: 70rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.social_footer > a {
  text-decoration: none;
  color: #fff;
  text-align: right;
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28rem */
}

.copyright {
  margin-top: 101rem;
  margin-bottom: 47rem;
}

.copyright > p {
  color: var(--PureWhite, #fff);
  text-align: center;
  font-family: 'Gotham Pro Light';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@media (max-width: 760px) {
  .footer_conteiner {
    position: relative;
    width: 100%;
    height: 1169rem;
    max-height: 1169rem;
    flex-shrink: 0;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(0deg, rgba(17, 59, 71, 0.52) 0%, #113b47 100%),
      url('./img/d61b45f6b8106e73f9fb3719e97d69d6.png') #133741;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: lightgray;
    overflow: hidden;
  }

  .footer {
    width: 100%;
    height: 1169rem;
    padding: 20rem 20rem 48rem 20rem;
  }

  .form_footer_conteiner {
    width: 335rem;
    height: 515rem;
    margin: 0rem auto auto auto;
    background-color: #fff;
  }

  .logo_footer {
    width: 58rem;
    height: 101rem;
    margin: 72rem auto auto auto;
    flex-shrink: 0;
  }

  .logo_footer > a > svg {
    width: 58rem;
    height: 101rem;
  }

  .adress_contacts_social {
    max-width: 272rem;
    height: 282rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 48rem auto auto auto;
  }

  .adress_map_footer {
    width: 272rem;
    height: 68rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    order: 2;
  }

  .adress_map_footer > p {
    width: 199rem;
    height: 42rem;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
  }

  .adress_map_footer > a {
    height: 14rem;
    text-decoration: none;
    color: var(--Gold, #b88b66);
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .tel_mail_footer {
    width: 271rem;
    height: 48rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    order: 1;
  }

  .tel_mail_footer :first-child {
    height: 18rem;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-family: 'Unbounded H3 Light';
    font-size: 24rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-transform: uppercase;
  }

  .tel_mail_footer :last-child {
    height: 14rem;
    text-decoration: none;
    color: #fff;
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .social_footer {
    width: 256rem;
    height: 70rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    order: 3;
  }

  .social_footer > a {
    text-decoration: none;
    color: #fff;
    text-align: right;
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .copyright {
    margin-top: 72rem;
    margin-bottom: 0;
  }

  .copyright > p {
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Gotham Pro Light';
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
