.choose_pool_render_conteiner {
  position: relative;
  max-width: 1920px;
  height: 1080px;
  max-height: 1080px;
  background: linear-gradient(
      0deg,
      rgba(13, 41, 49, 0.7) 0%,
      rgba(13, 41, 49, 0.7) 100%
    ),
    linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%),
    url('./img/82e84e0bc4c92e190a61fa1bb010d52a.png'), #595959;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-color: #f5f6f8;
}

.choose_pool_render {
  position: absolute;
  width: 1214rem;
  height: 667rem;
  bottom: 0;
  left: 353rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(-142rem);
}

.choose_pool_text {
  width: 816rem;
  height: 305rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.choose_pool_text > h1 {
  height: 236rem;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 Light';
  font-size: 64rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-transform: uppercase;
}

.choose_pool_text > h1 > span {
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

.choose_pool_text > p {
  height: 20rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Light';
  font-size: 28rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.choose_pool_buttons {
  width: 1214rem;
  height: 260rem;
  display: flex;
  flex-direction: row;
}

.choose_pool_button {
  position: relative;
  width: 607rem;
  height: 260rem;
  padding: 182rem 348rem 40rem 42rem;
  text-decoration: none;
  border: 1rem solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5rem);
  color: #fff;
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  transition-duration: 0.7s;
}

.choose_pool_button:hover {
  background-color: white;
  color: #000;
}

@media (max-width: 760px) {
  .choose_pool_render_conteiner {
    position: relative;
    max-width: 760px;
    height: 800rem;
    max-height: 800rem;
    background: linear-gradient(
        0deg,
        rgba(13, 41, 49, 0.7) 0%,
        rgba(13, 41, 49, 0.7) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%),
      url('./img/82e84e0bc4c92e190a61fa1bb010d52a.png'), #595959;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-color: #f5f6f8;
  }

  .choose_pool_render {
    position: absolute;
    width: 335rem;
    height: 498rem;
    bottom: 0;
    top: 163rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(0);
  }

  .choose_pool_text {
    width: 335rem;
    height: 148rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }

  .choose_pool_text > h1 {
    height: 82rem;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 Light';
    font-size: 26rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    text-transform: uppercase;
  }

  .choose_pool_text > h1 > span {
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 Light';
    font-size: 26rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    text-transform: uppercase;
  }

  .choose_pool_text > p {
    height: 38rem;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Light';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .choose_pool_buttons {
    width: 335rem;
    height: 296rem;
    display: flex;
    flex-direction: column;
  }

  .choose_pool_button {
    position: relative;
    width: 335rem;
    height: 148rem;
    padding: 54rem 58rem;
    border: 1rem solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.5rem);
    color: #fff;
    font-family: 'Gotham Pro';
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    transition-duration: 0.7s;
    text-align: center;
  }

  .choose_pool_button:hover {
    background-color: white;
    color: #000;
  }
}
