.not_found_conteiner {
    position: relative;
    max-width: 1920px;
    height: auto;
    margin: 0 auto;
    background-color: #113b47;
  }
  
  @media (max-width: 760px) {
    .not_found_conteiner {
      position: relative;
      max-width: 760px;
      height: auto;
      margin: 0 auto;
      background-color: #113b47;
    }
  }
  