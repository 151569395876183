.our_finished_saunas_conteiner {
  position: relative;
  max-width: 1920px;
  height: 796rem;
  max-height: 796px;
  background-color: #0d2931;
}

.our_finished_saunas {
  position: relative;
  max-width: 1920rem;
  height: 796rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.our_finished_saunas > h2 {
  height: 36rem;
  color: var(--PureWhite, #fff);
  text-align: center;
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.our_finished_saunas_slider {
  width: auto;
  height: 688rem;
}

@media (max-width: 760px) {
  .our_finished_saunas_conteiner {
    position: relative;
    max-width: 760px;
    height: 330rem;
    max-height: 330rem;
    background-color: #0d2931;
  }

  .our_finished_saunas {
    position: relative;
    max-width: 760rem;
    height: 330rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
  }

  .our_finished_saunas > h2 {
    height: 21rem;
    color: var(--PureWhite, #fff);
    text-align: center;
    font-family: 'Unbounded H2 Regular';
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .our_finished_saunas_slider {
    width: 375rem;
    height: 277rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
