.nav_menu_conteiner {
  width: 125rem;
  height: 52rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.burger_icon {
  display: flex;
  width: 52rem;
  height: 52rem;
  padding: 5rem 16rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  border-radius: 48rem;
  border: 1rem solid rgba(255, 255, 255, 0.1);
  background: var(--PureWhite, #fff);
}

.burger_icon > svg {
  color: #0d2931;
}

.menu_info > p {
  font-family: 'Gotham Pro';
  color: var(--PureWhite, #fff);
  font-size: 18rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

@media (max-width: 760px) {
  .nav_menu_conteiner {
    width: 48rem;
    height: 48rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .burger_icon {
    display: flex;
    width: 48rem;
    height: 48rem;
    padding: 4rem 14rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3rem;
    border-radius: 44.31px;
    border: 1rem solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
  }

  .burger_icon > svg {
    color: rgb(255, 255, 255);
  }

  .menu_info > p {
    display: none;
  }
}
