.burger_menu_conteiner {
  position: fixed;
  max-width: 1920px;
  max-height: 1080px;
  margin: 0 auto;
  background-color: rgba(13, 41, 49, 1);
  z-index: 0;
}

.burger_menu {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.burger_menu_left_side {
  position: absolute;
  width: 447rem;
  height: 59.16vh;
  transform: translate(353rem, 25.74vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger_menu_by_general_page {
  width: 447rem;
  height: 372rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger_menu_by_general_page > a,
.burger_menu_contacts > a > a {
  text-decoration: none;
}

.burger_menu_by_general_page > a > h2 {
  height: 48rem;
  color: var(--Light-Grey, #f5f6f8);
  font-family: 'Unbounded H1 Light';
  font-size: 64rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-transform: uppercase;
}

.burger_menu_by_min_page {
  width: 328rem;
  height: 92rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger_menu_by_min_page > a {
  text-decoration: none;
}

.burger_menu_by_min_page > a > h3 {
  height: 14rem;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.burger_menu_right_side {
  position: absolute;
  width: 447rem;
  height: 59.16vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate(1186rem, 25.74vh);
}

.burger_menu_contacts {
  position: absolute;
  width: 347rem;
  height: 108rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate(353rem, 80.74vh);
}

.burger_menu_contacts > a {
  height: 14rem;
  text-decoration: none;
  align-self: stretch;
  color: #fff;
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.burger_menu_contacts > p {
  height: 14rem;
  text-decoration: none;
  align-self: stretch;
  color: #fff;
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.burger_menu_contacts > :first-child {
  height: 18rem;
  color: #fff;
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.burger_menu_contacts :last-child {
  color: var(--Gold, #b88b66);
}

.burger_menu_socials {
  width: 116rem;
  height: 70rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger_menu_socials > a {
  text-decoration: none;
  color: #fff;
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 760px) {
  .burger_menu_conteiner {
    position: fixed;
    max-width: 760px;
    max-height: 100vh;
    margin: 0 auto;
    background-color: rgba(13, 41, 49, 1);
    z-index: 0;
  }

  .burger_menu {
    position: relative;
    width: 100vw;
    height: 100vh;
  }

  .burger_menu_left_side {
    position: absolute;
    width: 272rem;
    height: 144rem;
    transform: translate(0, 0);
    left: 0;
    right: 0;
    margin: 120rem auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .burger_menu_by_general_page {
    width: 168rem;
    height: 144rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
  }

  .burger_menu_by_general_page > a,
  .burger_menu_contacts > a > a {
    text-decoration: none;
  }

  .burger_menu_by_general_page > a > h2 {
    height: 18rem;
    color: var(--Light-Grey, #f5f6f8);
    font-family: 'Unbounded H1 Light';
    font-size: 24rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
  }

  .burger_menu_by_min_page {
    width: 262rem;
    height: 61rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .burger_menu_by_min_page > a {
    text-decoration: none;
  }

  .burger_menu_by_min_page > a > h3 {
    height: 11rem;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
  }

  .burger_menu_right_side {
    position: absolute;
    width: 272rem;
    height: 61rem;
    left: 0;
    right: 0;
    top: 300rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transform: translate(0, 0);
  }

  .burger_menu_contacts {
    position: relative;
    width: 272rem;
    height: 80rem;
    top: 480rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    transform: translate(0, 0);
  }

  .burger_menu_contacts > a {
    height: 11rem;
    text-decoration: none;
    align-self: stretch;
    color: #fff;
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .burger_menu_contacts > p {
    height: 11rem;
    text-decoration: none;
    align-self: stretch;
    color: #fff;
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .burger_menu_contacts > :first-child {
    height: 15rem;
    color: #fff;
    font-family: 'Unbounded H3 Light';
    font-size: 20rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-transform: uppercase;
  }

  .burger_menu_contacts :last-child {
    color: var(--Gold, #b88b66);
  }

  .burger_menu_socials {
    width: 272rem;
    height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .burger_menu_socials > a {
    text-decoration: none;
    color: #fff;
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
  }
}
