.style_choose_spa_zone_conteiner {
  position: relative;
  max-width: 1920px;
  height: 3200rem;
  max-height: 3200px;
  background-color: #0d2931;
}

.choose_spa_zone {
  position: relative;
  max-width: 1920px;
  height: 3200rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.spa_zone_card {
  max-width: 1920rem;
  height: 800rem;
  display: flex;
  flex-direction: row;
}

.cszi {
  width: 960rem;
  height: 800rem;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f5f6f8;
}

.snow_room_img {
  background-image: url('./img/4d133ca75331fd18a6fd594cbf2985f7.png');
}
.herbal_bath_img {
  background-image: url('./img/4887d5d511f88560867546be2605dbb1.png');
}
.salt_room_img {
  background-image: url('./img/56e4cfa5607401c3da2a863923025c2d.png');
}
.ice_hole_img {
  background-image: url('./img/433c13d567cc7826734d93a751eb36fd.png');
}

.cszt {
  width: 960rem;
  height: 800rem;
  padding: 0rem 119rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spa_zone_text_button_card {
  width: 722rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spa_zone_text_button_card > h2 {
  width: 722rem;
  height: 36rem;
  margin-bottom: 44rem;
  font-family: 'Unbounded H2 Regular';
  font-size: 48rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}

.spa_zone_text > h3 {
  width: 722rem;
  margin-bottom: 28rem;
  font-family: 'Unbounded H3 Light';
  font-size: 24rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;
}

.spa_zone_text > p {
  width: 722rem;
  height: auto;
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.spa_zone_text_button_card > button {
  width: 340rem;
  height: 72rem;
  padding: 26rem 48rem;
  margin-top: 44rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 38rem;
  background: var(--Gold, #b88b66);
  border: none;
  outline: none;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro Bold';
  font-size: 20rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.sztw {
  color: #fff;
}
.sztb {
  color: #212121;
}
.sztr {
  color: #83bdcd;
}

.sztg {
  color: #b88b66;
}

.herbal_bath,
.ice_hole {
  background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 760px) {
  .style_choose_spa_zone_conteiner {
    position: relative;
    max-width: 760px;
    height: auto;
    max-height: none;
    background-color: #0d2931;
  }

  .choose_spa_zone {
    position: relative;
    max-width: 760px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .spa_zone_card {
    max-width: 760rem;
    height: auto;
    padding: 20rem 0 40rem 0;
    display: flex;
    flex-direction: column;
  }

  .cszi {
    position: relative;
    width: 335rem;
    height: 335rem;
    margin: 0 auto;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f5f6f8;
  }

  .snow_room_img {
    background-image: url('./img/4d133ca75331fd18a6fd594cbf2985f7.png');
  }
  .herbal_bath_img {
    background-image: url('./img/4887d5d511f88560867546be2605dbb1.png');
  }
  .salt_room_img {
    background-image: url('./img/56e4cfa5607401c3da2a863923025c2d.png');
  }
  .ice_hole_img {
    background-image: url('./img/433c13d567cc7826734d93a751eb36fd.png');
  }

  .cszt {
    width: 375rem;
    height: auto;
    padding: 40rem 40rem 0 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .spa_zone_text_button_card {
    width: 295rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .spa_zone_text_button_card > h2 {
    width: 295rem;
    height: auto;
    margin-bottom: 40rem;
    font-family: 'Unbounded H2 Regular';
    font-size: 26rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
  }

  .spa_zone_text > h3 {
    width: 295rem;
    margin-bottom: 28rem;
    font-family: 'Unbounded H3 Light';
    font-size: 20rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-transform: uppercase;
  }

  .spa_zone_text > p {
    width: 295rem;
    height: auto;
    font-family: 'Gotham Pro';
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .spa_zone_text_button_card > button {
    width: 295rem;
    height: 56rem;
    padding: 16rem 48rem;
    margin-top: 44rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 38rem;
    background: var(--Gold, #b88b66);
    border: none;
    outline: none;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro Bold';
    font-size: 14rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }

  .sztw {
    color: #fff;
  }
  .sztb {
    color: #212121;
  }
  .sztr {
    color: #83bdcd;
  }

  .sztg {
    color: #b88b66;
  }

  .herbal_bath,
  .ice_hole {
    background-color: rgba(255, 255, 255, 1);
  }

  .herbal_bath_img {
    order: 1;
  }

  .herbal_bath_text {
    order: 2;
  }

  .ice_hole_img {
    order: 1;
  }

  .ice_hole_text {
    order: 2;
  }
}
