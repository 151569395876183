.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(13, 41, 49, 1);
  transform-origin: bottom;
  z-index: 100;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(13, 41, 49, 1);
  transform-origin: top;
  z-index: 100;
}

.button_hover {
  transition: 0.3s;
}

.button_hover:hover {
  cursor: pointer;
  background-color: whitesmoke;
  color: black;
  scale: 1.01;
}
