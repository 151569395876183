.presentation_spa_zone_conteiner {
  position: relative;
  max-width: 1920px;
  height: 100vh;
  max-height: 1080px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%),
    url('./img/5069c9e7d4921a368dd4a5961da28bd3.png'), #595959;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f5f6f8;
}

.presentation_spa_zone {
  position: relative;
  max-width: 1920px;
  max-height: 1080px;
  height: 100vh;
}

.bread_crumbs {
  position: absolute;
  width: 174rem;
  height: 11rem;
  top: 0;
  left: 18.33%;
  right: 72.6%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform: translateY(124rem);
}

.bread_crumbs > p {
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.presentation_spa_zone > h1 {
  position: absolute;
  width: 1212rem;
  height: 48rem;
  top: 0;
  left: 18.6%;
  right: 18.3%;
  color: var(--PureWhite, #fff);
  font-family: 'Unbounded H1 SemiBold';
  font-size: 64rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  transform: translateY(455rem);
}

.presentation_spa_zone > p {
  position: absolute;
  width: 592rem;
  height: 154rem;
  top: 0;
  left: 51%;
  right: 18.125%;
  color: var(--PureWhite, #fff);
  font-family: 'Gotham Pro';
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  transform: translateY(722rem);
}

@media (max-width: 760px) {
  .presentation_spa_zone_conteiner {
    position: relative;
    max-width: 760px;
    height: 500rem;
    max-height: 500rem;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(180deg, rgba(17, 59, 71, 0) 48.01%, #113b47 100%),
      url('./img/5069c9e7d4921a368dd4a5961da28bd3.png'), #595959;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f5f6f8;
  }

  .presentation_spa_zone {
    position: relative;
    max-width: 760px;
    max-height: 500rem;
    height: 500rem;
  }

  .bread_crumbs {
    display: none;
  }

  .bread_crumbs > p {
    display: none;
  }

  .presentation_spa_zone > h1 {
    position: relative;
    width: 335rem;
    height: 20rem;
    top: 233rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: var(--PureWhite, #fff);
    font-family: 'Unbounded H1 SemiBold';
    font-size: 26rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    transform: translateY(0);
    text-align: center;
  }

  .presentation_spa_zone > p {
    position: relative;
    width: 335rem;
    height: 144rem;
    top: 260rem;
    left: 0;
    right: 0;
    margin: auto;
    color: var(--PureWhite, #fff);
    font-family: 'Gotham Pro';
    font-size: 16rem;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
    transform: translateY(0);
    text-align: center;
  }
}
